<template>
  <a-spin :spinning="spinning">
    <div>
      <k-form-build :value="jsonData" />
    </div>
  </a-spin>
</template>
<script>
import * as Request from '@/api/starter';
import store from 'storejs';
import { Spin } from 'ant-design-vue';
import Vue from 'vue';
Vue.use(Spin);
export default {
  data() {
    return {
      jsonData: {},
      spinning: true
    };
  },
  created() {
    const formId = this.$route.params.formResourceId;
    const token = this.$route.params.token;
    store.set('FormVueToken', token);
    Request.formResourceDetail({ formResourceId: formId }).then(res => {
      if (res.success) {
        this.jsonData = JSON.parse(res.data.formJson);
      }
    });
  },
  mounted() {
    this.spinning = false;
  }
};
</script>
